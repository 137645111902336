import React from "react";
import Header2 from "../components/Header2";
import Showcase from "../components/Showcase";
import * as styles from "../styles/profile.module.css";

const profiles = ({ location, profileData, setProfile, show, handleShow }) => {
  return (
    <div className={styles.profilesWrapper}>
      <Header2 />
      <div className={styles.profilesShowcaseWrapper}>
        <Showcase
          data={location.state}
          profileData={profileData}
          setProfile={() => setProfile}
        />
      </div>
    </div>
  );
};

export default profiles;
